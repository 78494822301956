.title{
    font-family: Roboto;
    font-weight: 60px;
    font-size: 20px;
    /* color: red; */
}

.list:hover{
    overflow-x: overlay;
}

.list{
    display: flex;
    width: 100vw - 25;
    overflow-x: hidden;
    padding-bottom: 30px;
}

.tv_show_list_item{
    margin-right: 20px;
}