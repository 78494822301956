.main_container {
    display: flex;
    height: 100vh;
    padding: 25px;
    flex-direction: column;
    background-color: black;
}

.header {
    flex: 1;
    
}

.tv_show_detail {
    flex: 2;
}

.recommandations {
    flex: 1;
}