body {
    color: white;
    font-family: Maximum Impact;
}

::-webkit-scrollbar{
    background: transparent;
    height: 10px !important;
}

::-webkit-scrollbar-thumb:horizontal{
    background-color: rgba(0, 0, 0, 0.61);
    border-radius: 10px;
    height: 10px !important;
    
}