.title {
    font-weight: 400;
    font-size: 35px;
}
.rating {
    margin-top: 3px;
    margin-left: 5px;
}
.rating_container {
    display: flex;
}
.overview {
    font-family: roboto;
    font-weight: 400;
    font-size: 20px;
    height: 120px;
    overflow-y: hidden;
    margin-top: 10px;
}

.overview:hover{
    overflow-y: auto;
}