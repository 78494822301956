.input{
    border-radius: 30px;
    background-color: #d9d9d96e;
    height: 30px;
    font-weight: 100;
    font-size: 20px;
    width: 100%;
    border-color: transparent;
    padding: 18px 18px 18px 50px;
    color:white;
}
.icon{
    position: absolute;
    margin-top: 7px;
    margin-left: 15px;
}
.input::placeholder{
    color: rgb(194, 194, 194);
}
.input:focus{
    outline: 0px;
    border: 1px solid white;
}